<template>
    <div class="question-row">
        <app-modal
            v-if="isModalVisible"
            @closeModal="toggleModal"
            @saveHandler="toggleModal"
        >
            <app-editor v-model="question.intro" />
        </app-modal>

        <app-modal
            v-if="isNameModalVisible"
            @closeModal="toggleNameModal"
            @saveHandler="toggleNameModal"
        >
            <app-editor v-model="question.name" />
        </app-modal>

        <div
            v-if="!questionIsEdited"
            class="row"
        >
            <div class="number">
                {{ question.number }}
            </div>
            <div class="subtitle">
                {{ question.title }}
            </div>
            <div class="override_title">
                <label
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="question.is_override_title"
                        type="checkbox"
                        disabled
                    />
                    <div class="box"></div>
                </label>
            </div>
            <div class="intro-text">
                {{ question.intro | htmlToString }}
            </div>
            <div class="question">
                {{ question.name | htmlToString }}
            </div>
            <div
                v-if="activePollingQuestions"
                class="add-polling"
            >
                <app-button
                    class="add-polling-btn"
                    @click="openSurveys"
                >
                    <span v-if="!surveysIsOpen">Open</span>
                    <span v-else>Close</span>
                </app-button>
            </div>
            <div class="private-comment">
                <label
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="question.is_private"
                        type="checkbox"
                        disabled
                    />
                    <div class="box"></div>
                </label>
            </div>
            <div class="hide-comment">
                <label
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="question.hide_tool"
                        type="checkbox"
                        disabled
                    />
                    <div class="box"></div>
                </label>
            </div>
            <div class="edit-delete edit-delete-align">
                <app-actions
                    :edit="() => startEditQuestion(question)"
                    :trash="() => deleteQuestion(question)"
                />
            </div>
        </div>
        <div
            v-else
            class="row edit"
        >
            <div class="number">
                <div class="number-input">
                    <input
                        v-model="question.number"
                        type="text"
                        class="disposer-app-input"
                        :disabled="question.is_override_title === 1"
                    />
                </div>
            </div>

            <div
                class="subtitle"
            >
                <div class="subtitle-input">
                    <input
                        v-model="question.title"
                        type="text"
                        class="disposer-app-input title-input"
                        :disabled="!question.is_override_title"
                    />
                </div>
            </div>
            <div class="override_title">
                <label
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="question.is_override_title"
                        type="checkbox"
                    />
                    <div class="box"></div>
                </label>
            </div>

            <div class="intro-text">
                <div class="intro-text-input">
                    <input
                        :value="question.intro | htmlToString"
                        type="text"
                        class="disposer-app-input title-input"
                        @focus="toggleModal"
                    />
                </div>
            </div>

            <div class="question">
                <div class="question-input">
                    <input
                        :value="question.name | htmlToString"
                        type="text"
                        class="disposer-app-input title-input"
                        @focus="toggleNameModal"
                    />
                    <span
                        v-if="$v.question.name.$dirty && !$v.question.name.required"
                        class="app-input-error"
                    >
                        Enter name
                    </span>
                </div>
            </div>

            <div class="add-polling">
            </div>

            <div class="private-comment">
                <label class="disposer-app-checkbox">
                    <input
                        v-model="question.is_private"
                        type="checkbox"
                    />
                    <div class="box"></div>
                </label>
            </div>
            <div class="hide-comment">
                <label class="disposer-app-checkbox">
                    <input
                        v-model="question.hide_tool"
                        type="checkbox"
                    />
                    <div class="box"></div>
                </label>
            </div>

            <div class="edit-delete edit-delete-align">
                <app-actions
                    :save="() => saveQuestion(question)"
                    :trash="deleteQuestion"
                />
            </div>
        </div>
        <template v-if="surveysIsOpen">
            <app-topic-surveys
                v-for="survey in question.surveys"
                :key="survey.id"
                :survey="survey"
                :topic-id="topicId"
                :question-id="question.id"
                @deleteSurvey="deleteSurvey"
                @updateTopicInfo="getEditedTopic"
            />

            <app-button
                class="addSurvey"
                @click="addSurvey"
            >
                Add survey
            </app-button>
            <hr />
            <br />
            <h4 class="title-upload-section">
                Attach files to this question
            </h4>
            <app-section>
                <div class="disposer-app-topic-title">
                    Upload Documents
                </div>
                <app-upload-documents
                    v-for="document in documents"
                    :key="document.id"
                    :document="document"
                    :topic-id="topicId"
                    :question-id="question.id"
                    :event-id="eventId"
                    :type="3"
                    :paths="$options.documentActionsPaths"
                    @delete="deleteDocument"
                />
                <app-button
                    class="narrow"
                    @click="addDocument"
                >
                    Add document
                </app-button>
            </app-section>
            <app-section>
                <div class="disposer-app-topic-title">
                    Upload Videos
                </div>
                <app-upload-video
                    v-for="video in videos"
                    :key="video.id"
                    :video="video"
                    :upload-type="1"
                    :topic-id="topicId"
                    :event-id="eventId"
                    :type="3"
                    :question-id="question.id"
                    :paths="$options.documentActionsPaths"
                    @delete="deleteVideo"
                />
                <app-button
                    class="narrow"
                    @click="addVideo"
                >
                    Add video
                </app-button>
            </app-section>
            <app-section>
                <div class="disposer-app-topic-title">
                    Upload Audios
                </div>
                <app-upload-audio
                    v-for="audio in audios"
                    :key="audio.id"
                    :audio="audio"
                    :type="3"
                    :question-id="question.id"
                    :topic-id="topicId"
                    :event-id="eventId"
                    :paths="$options.documentActionsPaths"
                    @delete="deleteAudios"
                />
                <app-button @click="addAudio">
                    Add audio recording
                </app-button>
            </app-section>
            <br />
            <hr />
            <app-section
                :save="saveMainDocument"
                :delete-handler="deleteMainDocument"
                class="workshop-upload-document"
            >
                <div class="disposer-app-topic-title">
                    Attach document for review to this question
                </div>
                <div class="upload-current-file">
                    <a
                        :href="generateUrl(mainDocument.file)"
                        target="_blank"
                    >
                        {{ mainDocument.name }}
                    </a>
                </div>
                <div class="flex chooseFile">
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="mainDocument.secured"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Secured view-only format
                    </label>
                    <label
                        v-if="platform.settings.watermarks"
                        class="disposer-app-checkbox"
                    >
                        <input
                            v-model="mainDocument.watermarks"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Watermarks
                    </label>
                    <app-file-input
                        class="mini"
                        :load-file="loadMainDocument"
                        accept=".pdf"
                    >
                        Choose
                    </app-file-input>
                </div>
            </app-section>
            <hr />
            <br />
        </template>
    </div>
</template>

<script>
import appButton from '@/components/disposer/app/app-button';
import appActions from '@/components/disposer/app/app-actions';
import appTopicSurveys from '@/components/disposer/app/app-topic-surveys';
import appModal from '@/components/disposer/app/app-modal';
import appEditor from '@/components/disposer/app/app-editor';
import appFileInput from '@/components/disposer/app/app-file-input';
import appSection from '@/components/disposer/app/app-section';

import { post } from '@/utils/http-helper';
import {
    createQuestion,
    deletedDocument,
    deleteQuestion,
    saveDocument,
    updateQuestion,
} from '@/utils/disposer/notifications-text-helper';
import { required } from 'vuelidate/lib/validators';
import htmlToString from '@/utils/filters/html-to-string';
import generateUrl from '@/utils/mixins/generate_url';
import appUploadDocuments from '@/components/disposer/app/app-upload-documents/index.vue';
import appUploadAudio from '@/components/disposer/app/app-upload-audio/index.vue';
import appUploadVideo from '@/components/disposer/app/app-upload-video/index.vue';
import {
    AUDIO_TEMPLATE,
    DOCUMENT_TEMPLATE,
    VIDEO_TEMPLATE,
} from '@/utils/disposer/objects-templates';

const CREATE_QUESTION = '/topic/create-question';
const UPDATE_QUESTION = '/topic/update-question';
const DELETE_QUESTION = '/topic/delete-question';
const DELETE_MAIN_DOCUMENT = '/topic/delete-document';
const UPLOAD_MAIN_DOCUMENT = '/topic/upload-document';

export default {
    name: 'question-row',

    components: {
        appUploadVideo, appUploadAudio, appUploadDocuments,
        appSection,
        appFileInput,
        appButton,
        appActions,
        appTopicSurveys,
        appModal,
        appEditor,
    },


    filters: {
        htmlToString,
    },

    mixins: [generateUrl],

    documentActionsPaths: {
        SAVE_OBJECT: `/topic/create-object`,
        UPDATE_OBJECT: '/topic/update-object',
        DELETE_OBJECT: '/topic/delete-object',
    },

    props: {
        topicId: {
            type: Number,
            default: null,
        },
        eventId: {
            type: Number,
            default: null,
        },
        question: {
            type: Object,
            default: () => {
            },
        },
        activePollingQuestions: {
            type: Number,
            default: 1,
        },
    },

    data: () => ({
        isModalVisible: false,
        isNameModalVisible: false,
        edited: false,
        surveysIsOpen: false,
        mainDocument: {
            secured: false,
            watermarks: false,
            name: '',
            file: null,
        },
        videosIntro: [
            { ...VIDEO_TEMPLATE(Math.random(), 2) },
        ],
        audiosIntro: [
            { ...AUDIO_TEMPLATE(Math.random(), 2) },
        ],
        documentsIntro: [
            { ...DOCUMENT_TEMPLATE(Math.random(), 2) },
        ],

        documents: [
            { ...DOCUMENT_TEMPLATE(Math.random()) },
        ],
        documentsErrors: {},
        videos: [
            { ...VIDEO_TEMPLATE(Math.random()) },
        ],
        audios: [
            { ...AUDIO_TEMPLATE(Math.random()) },
        ],
    }),

    computed: {
        questionIsEdited() {
            return this.question.editDefault || this.edited;
        },

        platform() {
            return this.$store.getters['platform/platform'];
        },
    },

    validations: {
        question: {
            name: {
                required,
            },
        },
    },

    mounted() {
        this.mainDocument = {
            secured: !!this.question?.secured || false,
            watermarks: !!this.question?.watermarks || false,
            name: this.question?.file_name || '',
            file: this.question?.file || null,
        };
        const { documents, videos, audios } = this.question;
        if (documents) {
            this.documents = documents;
        }
        if (videos) {
            this.videos = videos;
        }
        if (audios) {
            this.audios = audios;
        }
    },

    methods: {
        toggleModal() {
            this.isModalVisible = !this.isModalVisible;
        },
        toggleNameModal() {
            this.isNameModalVisible = !this.isNameModalVisible;
        },
        toggleHideSection(question) {
            question.is_hide_section = +!question.is_hide_section;
            const params = {
                id: question.id ? question.id : null,
                topic_id: this.topicId,
                ...question,
            };

            let url = UPDATE_QUESTION;
            let successMessage = updateQuestion;
            this.$awn.async(post(url, params), response => {
                if (response.status) {
                    this.$awn.success(successMessage);
                }
            });
        },
        startEditQuestion() {
            this.edited = true;
        },
        openSurveys() {
            this.surveysIsOpen = !this.surveysIsOpen;
        },
        async deleteQuestion() {
            if (this.question.id >= 1) {
                this.$awn.async(post(DELETE_QUESTION, {
                    id: this.question.id,
                    topic_id: this.topicId,
                }), response => {
                    if (response.status) {
                        this.$emit('deleteQuestion', this.question.id);
                        this.$awn.success(deleteQuestion);
                    }
                });
            } else {
                this.$emit('deleteQuestion', this.question.id);
            }
        },
        async saveQuestion() {
            if (this.$v.question.$invalid) {
                this.$v.question.$touch();
                return;
            }

            const newQuestion = {
                event_id: this.eventId,
                topic_id: this.topicId,
                ...this.question,
            };
            newQuestion.hide_tool = +newQuestion.hide_tool;
            newQuestion.is_private = +newQuestion.is_private;
            newQuestion.is_override_title = +newQuestion.is_override_title;
            newQuestion.is_hide_section = +newQuestion.is_hide_section;
            delete newQuestion.surveys;
            delete newQuestion.id;

            let url = CREATE_QUESTION;
            let successMessage = createQuestion;

            if (this.question.id >= 1) {
                url = UPDATE_QUESTION;
                newQuestion.id = this.question.id;
                successMessage = updateQuestion;
            }

            this.$awn.async(post(url, newQuestion), response => {
                if (response.status) {
                    this.edited = false;
                    this.question.id = response.data.id;
                    this.question.editDefault = false;
                    this.$awn.success(successMessage);
                }
            });
        },
        addSurvey() {
            this.question.surveys.push({
                id: Math.random(),
            });
        },
        deleteSurvey(id) {
            this.question.surveys = this.question.surveys.filter(s => s.id !== id);
        },
        getEditedTopic() {
            this.$emit('getEditedTopic');
        },

        async saveMainDocument() {
            const fd = new FormData();
            fd.append('topic_id', this.topicId);
            fd.append('question_id', this.question.id);
            fd.append('watermark', +this.mainDocument.watermarks || 0);
            fd.append('secured', +this.mainDocument.secured || 0);
            if (typeof this.mainDocument.file !== 'string') {
                fd.append('file', this.mainDocument.file);
            }

            this.$awn.async(post(UPLOAD_MAIN_DOCUMENT, fd, true), saveDocument);
        },

        async deleteMainDocument() {
            this.$awn.async(post(DELETE_MAIN_DOCUMENT, {
                topic_id: this.topicId,
                event_id: this.eventId,
                question_id: this.question.id,
            }), response => {
                if (response.status) {
                    this.mainDocument = {
                        secured: false,
                        watermarks: false,
                        name: '',
                        file: null,
                    };
                    this.$awn.success(deletedDocument);
                }
            });
        },

        loadMainDocument(document) {
            this.mainDocument.name = document.name;
            this.mainDocument.file = document;
        },

        addDocument() {
            this.documents.push(DOCUMENT_TEMPLATE(Math.random()));
        },
        deleteDocument(id) {
            this.documents = this.documents.filter(d => d.id !== id);
        },
        addVideo() {
            this.videos.push(VIDEO_TEMPLATE(Math.random()));
        },
        deleteVideo(id) {
            this.videos = this.videos.filter(d => d.id !== id);
        },
        addAudio() {
            this.audios.push(VIDEO_TEMPLATE(Math.random()));
        },
        deleteAudios(id) {
            this.audios = this.audios.filter(d => d.id !== id);
        },
    },
};
</script>

<style scoped lang="scss">
.question-row {
    .title-upload-section {
        margin: 1rem;
    }

    &::v-deep {
        .flex {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 20px 0;
        }
    }

    .app-button {
        &.addSurvey {
            margin: 20px;
        }
    }

    .questions-table {
        display: flex;
        flex-direction: column;
        width: 100%; /* Задаём ширину таблицы */
    }

    .row-tr, .row {
        display: flex;
        align-items: center;
        justify-content: flex-start; /* Можно использовать flex-start для лучшего контроля */
    }

    .row-tr-head {
        padding: 10px 0;
    }

    .number {
        flex: 0 0 5%; /* 5% ширины для номера */
        text-align: center;
        padding: 10px;
    }

    .subtitle {
        flex: 0 0 11%; /* 20% ширины для подзаголовка и заголовка секции */
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: -webkit-box; /* Для поддержания поведения многострочного ограничения */
        -webkit-box-orient: vertical; /* Определяем вертикальную ориентацию */
        -webkit-line-clamp: 3; /* Показывать только три строки */
        overflow: hidden; /* Обрезать текст, который выходит за пределы */
        text-overflow: ellipsis; /* Добавить многоточие в конце */
        white-space: normal; /* Позволяет перенос строк */
    }

    .override_title {
        flex: 0 0 8%; /* 15% ширины для изменения заголовка */
        text-align: center;
        padding: 10px;
    }

    .intro-text {
        flex: 0 0 15%; /* 20% ширины для вводного текста */
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: -webkit-box; /* Для поддержания поведения многострочного ограничения */
        -webkit-box-orient: vertical; /* Определяем вертикальную ориентацию */
        -webkit-line-clamp: 3; /* Показывать только три строки */
        overflow: hidden; /* Обрезать текст, который выходит за пределы */
        text-overflow: ellipsis; /* Добавить многоточие в конце */
        white-space: normal; /* Позволяет перенос строк */
    }

    .question {
        flex: 0 0 30%; /* 23% ширины для вопроса */
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: -webkit-box; /* Для поддержания поведения многострочного ограничения */
        -webkit-box-orient: vertical; /* Определяем вертикальную ориентацию */
        -webkit-line-clamp: 3; /* Показывать только три строки */
        overflow: hidden; /* Обрезать текст, который выходит за пределы */
        text-overflow: ellipsis; /* Добавить многоточие в конце */
        white-space: normal; /* Позволяет перенос строк */
    }


    .add-polling {
        flex: 0 0 11%; /* 10% ширины для открывания опроса */
        text-align: center;
        padding: 10px;
        display: flex;
        justify-content: center;
    }

    .private-comment {
        flex: 0 0 7%; /* 5% ширины для приватного комментария и скрытого комментария */
        text-align: center;
        padding: 10px;
    }

    .hide-comment {
        flex: 0 0 7%; /* 5% ширины для приватного комментария и скрытого комментария */
        text-align: center;
        padding: 10px;
    }

    .edit-delete {
        flex: 0 0 6%; /* 5% ширины для кнопок редактирования и удаления */
        text-align: center;
        padding: 10px;
        display: flex;
        justify-content: center;
    }

}

.chooseFile {
    display: flex;
    justify-content: space-between !important;
    width: 34.375rem !important;
    padding: 1.25rem 0 !important;
}
</style>
