<template>
    <div class="comment-popup">
        <div class="comment-popup_title">
            {{ $t('your_comment', 'Your Comment') }}
        </div>
        <form @submit.prevent="sendComment">
            <label class="textarea-label">
                {{ $t('comment', 'Comment') }}:
                <textarea
                    v-model="comment"
                    class="app-input"
                ></textarea>
            </label>
            <app-button
                tag="button"
                type="submit"
                class="accent"
            >
                {{ $t('submit', 'Submit') }}
            </app-button>
        </form>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';

import { GP } from '@/utils/sockets-helper';

export default {
    name: 'comment-popup',
    components: {
        appButton,
    },
    props: {
        socket: {
            type: Object,
            default: null,
        },
        paths: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        comment: '',
    }),

    computed: {
        options() {
            return this.$store.getters.popupOptions;
        },

        generateSocketPath() {
            return new GP(this.paths.NAME_SPACE);
        },
    },
    mounted() {
        this.comment = this.options.commentContent || '';
    },

    methods: {
        sendComment() {
            if (this.comment && !this.options.editing) {
                this.socket.emit(this.generateSocketPath.generate(this.paths.CREATE_TEXT), {
                    topic_id: +this.options.topicId,
                    content: this.comment,
                    parent_id: this.options.parentId || 0,
                    question_id: this.options.questionId || 0,
                    all: this.options.toAll || 0,
                }, data => {
                    if (data.success) {
                        this.$emit('createComment', data);
                    }
                    this.$store.dispatch('togglePopup', { popupName: null });
                });
            }
            if (this.comment && this.options.editing) {
                this.socket.emit(this.generateSocketPath.generate('comment-edit'), {
                    content: this.comment,
                    question_id: this.options.questionId,
                    comment_id: this.options.commentId,
                }, data => {
                    if (data.success) {
                        this.$emit('editComment', data);
                    }
                    this.$store.dispatch('togglePopup', { popupName: null });
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.comment-popup {
    .comment-popup_title {
        margin-bottom: 2rem;
    }

    .textarea-label {
        font-size: 0.8rem;
        font-weight: 700;
        color: $copyGray;
    }

    textarea {
        height: 8rem;
        margin: 1rem 2px 2px;

        resize: none;
    }

    .app-button {
        margin-top: 1rem;
        margin-left: auto;
    }
}
</style>
