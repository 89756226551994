<template>
    <div class="app-comments">
        <div
            v-for="comment in comments"
            :key="comment.id"
            class="app-comments__discuss"
            :class="{hover: comment.hover}"
        >
            <div
                class="app-comments__comment app-comments__comment__main"
                :class="{short: type==='short', forAll: comment.type===4, hover: comment.hover}"
                :style="{background: `${comment.color}30`, borderColor: comment.color}"
                @mouseenter="toggleComment(comment, true)"
                @mouseleave="toggleComment(comment, false)"
            >
                <img :src="generateUrl(comment.avatar)" />
                <div class="info">
                    <div class="name">
                        {{ comment.name }}
                        <span
                            v-if="comment.color"
                            class="online"
                            :style="{background: comment.color}"
                        >
                            {{ comment.number || '' }}
                        </span>
                    </div>
                    <div class="line">
                        <div class="left">
                            <div class="info">
                                <div class="date">
                                    {{ comment.date }} |
                                    <span @click="toggleComments(comment)">
                                        {{
                                            comment.count_comments
                                        }} {{ $t('dash_comments', 'Comments') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="right"
                            :class="{top: type==='short'}"
                        >
                            <div
                                v-if="commentIsMine(comment.user_id) && !comment.deleted"
                                class="comment"
                                :data-disabled="!topicIsActive"
                                :class="{blue: type==='short'}"
                                @click="editComment(comment.id, questionId, comment.content)"
                            >
                                <i
                                    class="fa fa-edit"
                                ></i>
                                {{ $t('dash_edit', 'Edit') }}
                            </div>
                            <div
                                v-if="type==='standard'"
                                class="likes"
                                :class="{deleted: comment.deleted}"
                                :data-disabled="!topicIsActive"
                                @click="like(comment)"
                                v-html="likes(comment)"
                            ></div>
                            <div
                                v-if="type==='standard'"
                                v-click-outside="closeInfoLikes"
                                class="group"
                                :class="{noEvents: !comment.count_likes}"
                                @click="getInfoLikes(comment)"
                            >
                                <img src="@/assets/img/icons/group_icon.svg" />

                                <div
                                    v-if="infoLikes && infoLikes.comment_id === comment.id && infoLikes.users.length"
                                    class="likesList"
                                >
                                    <div
                                        v-for="user in infoLikes.users"
                                        :key="user.id"
                                        class="user"
                                    >
                                        <img :src="generateUrl(user.avatar)" />
                                        <div class="name">
                                            {{ user.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="!comment.deleted"
                                class="comment"
                                :class="{blue: type==='short'}"
                                :data-disabled="!topicIsActive"
                                @click="addComment(comment.id, 0, questionId)"
                            >
                                {{ $t('dash_comment', 'Comment') }}
                            </div>
                            <div
                                v-if="commentIsMine(comment.user_id)"
                                class="delete"
                                :class="{blue: type==='short'}"
                                :data-disabled="!topicIsActive"
                                @click="$emit('toggleCommentState', comment.id, comment.deleted)"
                            >
                                <i
                                    v-if="!comment.deleted"
                                    class="fa fa-trash"
                                ></i>
                                <i
                                    v-else
                                    class="fa fa-refresh"
                                ></i>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="comment.type===1 || comment.type===4 || comment.deleted || type==='short'"
                        class="text"
                    >
                        {{ comment.content }}
                    </div>
                    <audio
                        v-else-if="comment.type===2 && !comment.deleted"
                        :src="generateFileUrl(comment.extra)"
                        controls
                    ></audio>
                    <a
                        v-else-if="comment.type===3"
                        class="text"
                        :href="generateFileUrl(comment.extra)"
                        target="_blank"
                    >
                        {{ comment.content }}
                    </a>
                </div>
            </div>
            <template v-if="comment.id === openComments">
                <div
                    v-for="subComment in comment.comments"
                    :key="subComment.id"
                    class="app-comments__comment app-comments__comment__sub"
                    :class="{short: type==='short'}"
                >
                    <img :src="generateUrl(subComment.avatar)" />
                    <div class="info">
                        <div class="name">
                            {{ subComment.name }}
                        </div>
                        <div class="line">
                            <div class="left">
                                <div class="info">
                                    <div
                                        class="date"
                                        v-html="subComment.date"
                                    ></div>
                                </div>
                            </div>
                            <div
                                class="right"
                                :class="{top: type==='short'}"
                            >
                                <div
                                    v-if="commentIsMine(comment.user_id) && !comment.deleted"
                                    class="comment"
                                    :data-disabled="!topicIsActive"
                                    :class="{blue: type==='short'}"
                                    @click="editComment(subComment.id, questionId, subComment.content)"
                                >
                                    <i
                                        class="fa fa-edit"
                                    ></i>
                                    {{ $t('dash_edit', 'Edit') }}
                                </div>
                                <div
                                    v-if="type==='standard'"
                                    class="likes"
                                    :class="{deleted: comment.deleted}"
                                    :data-disabled="!topicIsActive"
                                    @click="like(subComment)"
                                    v-html="likes(subComment)"
                                ></div>
                                <div
                                    v-if="type==='standard'"
                                    v-click-outside="closeInfoLikes"
                                    class="group"
                                    :class="{noEvents: !subComment.count_likes}"
                                    @click="getInfoLikes(subComment)"
                                >
                                    <img src="@/assets/img/icons/group_icon.svg" />

                                    <div
                                        v-if="infoLikes && infoLikes.comment_id === subComment.id && infoLikes.users.length"
                                        class="likesList"
                                    >
                                        <div
                                            v-for="user in infoLikes.users"
                                            :key="user.id"
                                            class="user"
                                        >
                                            <img :src="generateUrl(user.avatar)" />
                                            <div class="name">
                                                {{ user.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="commentIsMine(subComment.user_id)"
                                    class="delete"
                                    :class="{blue: type==='short'}"
                                    :data-disabled="!topicIsActive"
                                    @click="$emit('toggleCommentState', subComment.id, subComment.deleted)"
                                >
                                    <i
                                        v-if="!subComment.deleted"
                                        class="fa fa-trash"
                                    ></i>
                                    <i
                                        v-else
                                        class="fa fa-refresh"
                                    ></i>
                                </div>
                                <div
                                    v-if="type==='short'"
                                    class="trash"
                                >
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="text">
                            {{ subComment.content }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import generateUrl from '@/utils/mixins/generate_url';
import { generateFileUrl, get } from '@/utils/http-helper';
import vClickOutside from 'v-click-outside';

const GET_LIKES = '/dashboard/get-likes';

export default {
    name: 'app-comments',

    directives: {
        clickOutside: vClickOutside.directive,
    },

    mixins: [generateUrl],

    props: {
        type: {
            type: String,
            default: 'standard',
        },
        comments: {
            type: Array,
            default: null,
        },
        questionId: {
            type: Number,
            default: null,
        },
        topicIsActive: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        openComments: null,
        infoLikes: null,
    }),

    computed: {
        userId() {
            return this.$store.getters['auth/userInfo']?.id;
        },
        color() {
            return this.$store.getters['auth/userInfo']?.color;
        },
    },

    methods: {
        generateFileUrl(file) {
            return generateFileUrl(file);
        },

        commentIsMine(id) {
            return +id === +this.userId;
        },
        like(comment) {
            if (!comment.deleted) {
                this.$emit('like', comment.id, comment.liked, this.questionId);
            }
        },
        addComment() {
            this.$emit('comment', ...arguments);
        },
        editComment() {
            this.$emit('edit', ...arguments);
        },
        likes(comment) {
            return `${this.$t(comment.liked ? 'dash_liked' : 'dash_like', 'Like')}&nbsp;&nbsp;(${comment.count_likes})`;
        },
        toggleComment(comment, state) {
            this.$emit('updateComments', comment, state);
        },
        toggleComments(comment) {
            if (this.openComments === comment.id) {
                this.openComments = null;
            } else {
                this.openComments = comment.id;
            }
        },
        async getInfoLikes(comment) {
            const infoLikes = await get(GET_LIKES, {
                comment_id: comment.id,
            });
            this.infoLikes = {
                comment_id: comment.id,
                users: infoLikes.data.likes,
            };
        },
        closeInfoLikes() {
            this.infoLikes = null;
        },
    },
};
</script>

<style scoped lang="scss">
.app-comments {
    height: 100%;
    padding: 1rem 1rem 0;
    overflow: auto;

    background: white;

    &__comment {
        position: relative;

        display: flex;
        align-items: flex-start;

        margin-bottom: 1rem;
        padding: 1.7rem 1.5rem 1.9rem 1.1rem;

        border: 3px solid $lightGray;

        &.forAll {
            .text {
                color: rgb(0, 0, 238);
            }
        }

        &:not(.hover) {
            background: transparent !important;
            border-color: $lightGray !important;
        }

        img {
            width: 4rem;
            margin-right: 1.1rem;
        }

        .name {
            display: flex;
            align-items: center;

            margin-bottom: 0.2rem;

            font-size: to_rem(18px);
            font-weight: 700;
            color: var(--platform-accent-color);

            .online {
                display: flex;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;

                width: 1.15rem;
                height: 1.15rem;
                margin-left: 0.3rem;

                font-size: to_rem(12px);
                color: black;

                background: $yellow;
                border-radius: 50%;
            }
        }

        .line {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 0.8rem;
        }

        .info {
            width: 100%;

            span {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .left {
            display: flex;
            align-items: flex-start;

            .date {
                width: 15rem;
                margin-right: -2.3rem;

                font-size: to_rem(13px);
                color: $gray;

                &::v-deep {
                    a {
                        color: $gray;

                        cursor: pointer;
                    }
                }
            }
        }

        .right {
            position: absolute;
            top: 0.2rem;
            right: 0.75rem;

            display: flex;
            align-items: center;

            font-size: to_rem(13px);
            color: $gray;

            .group {
                position: relative;
                order: 2;
                width: 1rem;
                margin: 0 0 0 0.5rem;

                &.noEvents {
                    pointer-events: none;
                }

                img {
                    width: 100%;
                }

                .likesList {
                    position: absolute;
                    top: 110%;
                    right: 0;

                    min-width: 10rem;
                    padding: 0.5rem;

                    background: white;
                    border: 1px solid $gray;
                    box-shadow: 3px 5px 12px 0px rgb(0 0 0 / 30%);

                    .user {
                        display: flex;
                        align-items: center;

                        &:not(:last-of-type) {
                            margin-bottom: 0.2rem;
                        }

                        img {
                            width: 1.5rem;
                            margin-right: 0.5em;
                        }

                        .name {
                            margin: 0;

                            font-size: to_rem(14px);
                            color: black;
                        }
                    }
                }
            }

            .likes {
                position: relative;
                margin-left: 0.5rem;
                padding-left: 1rem;

                &[data-disabled] {
                    pointer-events: none;
                }

                &:hover {
                    text-decoration: underline;
                }

                &:before {
                    @include bef_aft;
                    //left: -1rem;
                    left: 0;

                    width: 0.7rem;
                    height: .8rem;

                    background: url("~@/assets/img/icons/like_icon.svg") no-repeat center center/contain;

                    transform: scaleX(-1);
                }

                &.deleted {
                    cursor: default;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .comment {
                margin-left: 0.5rem;

                &[data-disabled] {
                    pointer-events: none;
                }

                &:hover {
                    text-decoration: underline;
                }

                &:nth-child(1) {
                    order: 4;
                }

                &:nth-child(2) {
                    order: 3;
                }
            }

            .delete {
                margin-left: 0.5rem;
                order: 5;
                font-size: 1.2em;

                &[data-disabled] {
                    pointer-events: none;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }

            .blue {
                color: var(--platform-accent-color);
            }

            div {
                cursor: pointer;
            }
        }

        .text {
            font-size: to_rem(14px);
            word-break: break-word;
            overflow-wrap: anywhere;
        }

        &__sub {
            margin-left: 6rem;
        }

        &.short {
            padding: 1rem 0.5rem 1rem 0.5rem;

            img {
                width: 3rem;
                margin-right: 0.5rem;
            }

            .name {
                font-size: 0.875rem;
            }

            .left {
                .date {
                    font-size: 0.75rem;
                }
            }
        }
    }
}

@include mobile_or_P {
    .app-comments {
        &__comment {
            margin-bottom: 0.7rem;
            padding: 1.7rem 0.5rem 1.9rem 1rem;

            .right {
                .comment {
                    margin-left: 0.5rem;

                    &:nth-child(1) {
                        order: 4;
                    }

                    &:nth-child(2) {
                        order: 3;
                    }
                }
            }

            audio {
                width: 100%;
            }
        }

        .right {
            .delete {
                margin-left: 1rem;
            }
        }
    }
}

@include razr767 {
    .app-comments {
        padding: 0.75rem 1rem;

        &__comment {
            padding: 1.2rem 0.5rem 2.5rem 1rem;

            img {
                width: 4.8rem;
                margin-top: 0.5rem;
                margin-right: 1.25rem;
            }

            .group {
                img {
                    margin-top: 0;
                }
            }

            .name {
                margin-top: 0.6rem;
                margin-bottom: 0;

                font-size: 1.36rem;
            }

            .info {
                width: 76%;
            }

            .left {
                .date {
                    width: 8.8rem;

                    font-size: 0.85rem;
                    line-height: 1.4;
                }
            }

            .text {
                position: relative;
                left: -6rem;

                margin-top: 2rem;

                font-size: 1rem;
            }

            .right {
                font-size: 0.9rem;

                .group {
                    img {
                        margin-right: 0;
                    }
                }
            }

            &__sub {
                margin-left: 1rem;
            }

            &.short {
                padding-top: 2rem;

                .text {
                    left: -3rem;
                }

            }
        }
    }
}
</style>
